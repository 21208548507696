<!-- eslint-disable prettier/prettier -->
<template>
  <section class="conOfMissionVission h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage
            :headerPage="{ part1: 'SSBD', part2: 'Branches' }"
          />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="clearBtn secondary-outline-btn my-3"
              href="/pdf/Branches.pdf"
              target="_blank"
            >
              Preview Branches pdf
            </v-btn>
          </v-col>
          <v-col cols="12">
            <!-- <iframe
              :src="pdfsrc"
              style="width: 100%; height: 800px; border: none"
            >
              Oops! an error has occurred.
            </iframe> -->
            <embed
              :src="pdfsrc"
              width="100%"
              height="800"
              type="application/pdf"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "About Us",
        disabled: true,
      },
      {
        text: "Branches",
        disabled: false,
      },
    ],
    pdfsrc: "/pdf/Branches.pdf#toolbar=0&navpanes=1&scrollbar=0",
  }),
  components: {
    HeaderPage,
    Breadcrumb,
  },
};
</script>
<style lang="scss" scoped>
@import "./_founding-regulations.scss";
</style>
